import * as React from 'react'
import { Icon } from '@iconify/react';
import googleFill from '@iconify/icons-eva/google-fill';
import twitterFill from '@iconify/icons-eva/twitter-fill';
import facebookFill from '@iconify/icons-eva/facebook-fill';
import linkedinFill from '@iconify/icons-eva/linkedin-fill';
import { Link as ScrollLink } from 'react-scroll';
// material
import { styled } from '@mui/material/styles';
import { Grid, Divider, Container, Typography, IconButton, Stack } from '@mui/material';
import { Link } from "gatsby-theme-material-ui";
// routes
import { PATH_PAGE } from '../../_demo/routes/paths';
//
import Logo from '../../components/Logo';

// ----------------------------------------------------------------------

const SOCIALS = [
  { name: 'FaceBook', icon: facebookFill },
  { name: 'Google', icon: googleFill },
  { name: 'Linkedin', icon: linkedinFill },
  { name: 'Twitter', icon: twitterFill }
];

const LINKS = [
  {
    headline: 'Heroes',
    children: [
      { name: 'About us', href: "/about" },
      { name: 'Contact us', href: "/contact" },
      { name: 'FAQs', href: "/faq" }
    ]
  },
  {
    headline: 'Legal',
    children: [
      { name: 'Terms and Condition', href: '/terms' },
      { name: 'Privacy Policy', href: '/privacy' }
    ]
  },
  {
    headline: 'Contact',
    children: [
      { name: 'support@heroes.local', href: '#' },
      { name: 'City, Street 123', href: '#' }
    ]
  }
];

const RootStyle = styled('div')(({ theme }) => ({
  position: 'relative',
  backgroundColor: theme.palette.background.default
}));

// ----------------------------------------------------------------------

export default function MainFooter() {
  return (
    <RootStyle>
      <Divider />
      <Container maxWidth="lg" sx={{ pt: 10 }}>
        <Grid container justifyContent={{ xs: 'center', md: 'space-between' }} sx={{ textAlign: { xs: 'center', md: 'left' } }}>
          <Grid item xs={12} sx={{ mb: 3 }}>
            <ScrollLink to="move_top" spy smooth>
              <Logo sx={{ mx: { xs: 'auto', md: 'inherit' } }} />
            </ScrollLink>
          </Grid>
          <Grid item xs={8} md={3}>
            <Typography variant="body2" sx={{ pr: { md: 5 } }}>
              The starting point for your next project.
            </Typography>

            <Stack spacing={1.5} direction="row" justifyContent={{ xs: 'center', md: 'flex-start' }} sx={{ mt: 5, mb: { xs: 5, md: 0 } }} >
              {SOCIALS.map((social) => (
                <IconButton key={social.name} color="primary" sx={{ p: 1 }}>
                  <Icon icon={social.icon} width={16} height={16} />
                </IconButton>
              ))}
            </Stack>
          </Grid>

          <Grid item xs={12} md={7}>
            <Stack spacing={5} direction={{ xs: 'column', md: 'row' }} justifyContent="space-between">
              {LINKS.map((list) => {
                const { headline, children } = list;
                return (
                  <Stack key={headline} spacing={2}>
                    <Typography component="p" variant="overline">
                      {headline}
                    </Typography>
                    {children.map((link) => (
                      <Link to={link.href} key={link.name} color="inherit" variant="body2" sx={{ display: 'block' }} >
                        {link.name}
                      </Link>
                    ))}
                  </Stack>
                );
              })}
            </Stack>
          </Grid>
        </Grid>

        <Typography component="p" variant="body2" sx={{ mt: 10, pb: 5, fontSize: 13, textAlign: { xs: 'center', md: 'left' } }} >
          © 2021. All rights reserved
        </Typography>
      </Container>
    </RootStyle>
  );
}