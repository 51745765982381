import * as React from 'react'
//import { /*NavLink as RouterLink,*/ useLocation } from 'react-router-dom';
import { GatsbyLink as RouterLink } from "gatsby-theme-material-ui";
import { Link } from "gatsby"
// material
import { styled } from '@mui/material/styles';
import { Box, /*Button,*/ AppBar, Toolbar, Container, Avatar } from '@mui/material';
import { Button } from 'gatsby-theme-material-ui';
// hooks
import useOffSetTop from '../../hooks/useOffSetTop';
import { useAuth0 } from '@auth0/auth0-react';
// components
import Logo from '../../components/Logo';
import Label from '../../components/Label';
import { MHidden } from '../../components/@material-extend';
//
import MenuDesktop from './MenuDesktop';
import MenuMobile from './MenuMobile';
import navConfig from './MenuConfig';


// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 88;

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  height: APP_BAR_MOBILE,
  transition: theme.transitions.create(['height', 'background-color'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter
  }),
  [theme.breakpoints.up('md')]: {
    height: APP_BAR_DESKTOP
  }
}));

const ToolbarShadowStyle = styled('div')(({ theme }) => ({
  left: 0,
  right: 0,
  bottom: 0,
  height: 24,
  zIndex: -1,
  margin: 'auto',
  borderRadius: '50%',
  position: 'absolute',
  width: `calc(100% - 48px)`,
  boxShadow: theme.customShadows.z8
}));

// ----------------------------------------------------------------------

/*export default function MainNavbar() {
  const isOffset = useOffSetTop(100);
  const { pathname } = useLocation();
  const isHome = pathname === '/';

  return (
    <AppBar sx={{ boxShadow: 0, bgcolor: 'transparent' }}>
      <ToolbarStyle
        disableGutters
        sx={{
          ...(isOffset && {
            bgcolor: 'background.default',
            height: { md: APP_BAR_DESKTOP - 16 }
          })
        }}
      >
        <Container
          maxWidth="lg"
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <RouterLink to="/">
            <Logo />
          </RouterLink>
          <Label color="info" sx={{ ml: 1 }}>
            v2.6.0
          </Label>
          <Box sx={{ flexGrow: 1 }} />

          <MHidden width="mdDown">
            <MenuDesktop isOffset={isOffset} isHome={isHome} navConfig={navConfig} />
          </MHidden>

          <Button variant="contained" target="_blank" href="https://material-ui.com/store/items/minimal-dashboard/">
            Purchase Now
          </Button>

          <MHidden width="mdUp">
            <MenuMobile isOffset={isOffset} isHome={isHome} navConfig={navConfig} />
          </MHidden>
        </Container>
      </ToolbarStyle>

      {isOffset && <ToolbarShadowStyle />}
    </AppBar>
  );
}*/

export default function MainNavbar() {
  const isOffset = useOffSetTop(100);
  const { pathname } = "";//useLocation();
  const isHome = false; //pathname === '/';

  const {
    isLoading,
    isAuthenticated,
    error,
    user,
    loginWithRedirect,
    logout,
  } = useAuth0();

  return (
    <AppBar sx={{ boxShadow: 0, bgcolor: 'transparent' }}>
      
      <ToolbarStyle disableGutters sx={{ ...(isOffset && { bgcolor: 'background.default', height: { md: APP_BAR_DESKTOP - 16 } }) }}>
        <Container maxWidth="lg" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between'}} >
          <Link to="/">
            <Logo />
          </Link>
          {/*<Label color="info" sx={{ ml: 1 }}>
            v2.6.0
            </Label>*/}
          <Box sx={{ flexGrow: 1 }} />

          <MHidden width="mdDown">
            <MenuDesktop isOffset={isOffset} isHome={isHome} navConfig={navConfig} />
          </MHidden>

          {isAuthenticated ?  (
            <>
              {/*Hello {user.given_name}{'! '}*/}
              <Button variant="contained" sx={{ mr: 1}}  to="/user">
                <Avatar alt={user.given_name} src={user.picture} sx={{ width: 24, height: 24, mr: 1 }}/>{/*user.given_name*/} My Profile
              </Button>

              <Button variant="outlined" sx={{ mr: 1}} onClick={() => logout({ returnTo: window.location.origin })}>
                Log out
              </Button>
            </>
            ) :(
            <>
              <Button variant="outlined" sx={{ mr: 1 }} to="/login">
                Login
              </Button>
              <Button variant="contained"  to="/sign-up">
                Sign up
              </Button>
            </>
          )}

          <MHidden width="mdUp">
            <MenuMobile isOffset={isOffset} isHome={isHome} navConfig={navConfig} />
          </MHidden> 
   
        </Container>
      </ToolbarStyle>

      {isOffset && <ToolbarShadowStyle />}
    </AppBar>
  );
}